import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}my-account`} component={asyncComponent(() => import('../containers/Sidebar/Account'))} />
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))} />
      <Route path={`${match.url}citizens-dashboard`} component={asyncComponent(() => import('./CitizensManagement'))} />
      <Route path={`${match.url}mobile-announcements`} component={asyncComponent(() => import('./Announcements/MobileAnnouncements'))} />
      <Route path={`${match.url}billboard-announcements`} component={asyncComponent(() => import('./Announcements/BillboardAnnouncements'))} />
    </Switch>
  </div>
);

export default App;
