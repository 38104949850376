import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import { PhoneOutlined } from "@ant-design/icons";
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          {/* <AppsNavigation /> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline">
            <Menu.Item key="dashboard">
              <Link to="/dashboard"><i className="icon icon-dasbhoard" />
                <span><IntlMessages id="sidebar.dashboard" /></span>
              </Link>
            </Menu.Item>
            <Menu.Item key="citizens-dashboard">
              <Link to="/citizens-dashboard"><i className="icon fas fa-user-friends" />
                <span><IntlMessages id="sidebar.citizens" /></span>
              </Link>
            </Menu.Item>
            <MenuItemGroup key="announcements" className="gx-menu-group"
              title={<IntlMessages id="sidebar.announcements" />}>
              <Menu.Item key="billboard-announcements">
                <Link to="/billboard-announcements"><i className="icon fas fa-desktop" />
                  <span><IntlMessages id="sidebar.billboard" /></span>
                </Link>
              </Menu.Item>

              <Menu.Item key="mobile-announcements">
                <Link to="/mobile-announcements"><i className="icon fas fa-mobile" />
                  <span><IntlMessages id="sidebar.mobile" /></span>
                </Link>
              </Menu.Item>
            </MenuItemGroup>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

