import React from "react";
import {Avatar, Popover} from "antd";
import {useAuth} from "../../authentication";
import {useHistory, NavLink} from "react-router-dom";

const UserProfile = () => {
  const {userSignOut} = useAuth();
  const history = useHistory();

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <NavLink to="/my-account"><li>My Account</li></NavLink>
      <li onClick={onLogoutClick}>Logout
      </li>
    </ul>
  );
  
  const userImg = localStorage.getItem("user_image")
  const newUserImg = userImg.replace("/profile_images/", "/profile_images%2F")

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={newUserImg} className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{localStorage.getItem("username")}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};

export default UserProfile;
