import axios from 'axios';

// DEV 
// export const mapUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyABo9FPNN3Lb6Jz9uV-_9OaSKM5kmKGhIc&v=3.exp&libraries=geometry,drawing,places"; // MAPS
// export const apiUrl = "https://cadapi-dev.comclark.tech"; // API
// export const axiosUrl = "https://cadapi-dev.comclark.tech/" // Axios

// PROD 
export const mapUrl = "https://maps.googleapis.com/maps/api/js?key=AIzaSyAdYBMjnhBOpY5F9tc8r4n6ij5FDYgvR-o&v=3.exp&libraries=geometry,drawing,places"; // MAPS
export const apiUrl = "https://cad-api.cicto-csfp.com"; // API
export const axiosUrl = "https://cad-api.cicto-csfp.com/" // Axios

export default axios.create({
  baseURL: axiosUrl, 
  headers: {
    'Content-Type': 'application/json',
  },
});

//WEATHER API
export const apiWeatherPath = "https://api.openweathermap.org/data/2.5";